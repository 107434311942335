import React from 'react';
import './Styles/DegreePlan.css'; // Optional for custom styles


const DegreePlan = () => {
    return (
        <div>
            <h2>Degree Plan</h2>
            <p>Here you can view and manage your degree plan.</p>
        </div>
    );
};

export default DegreePlan;