import React from 'react';
import './Styles/Grades.css'; // Optional for custom styles

const Grades = () => {
    return (
        <div>
            <h2>Grades</h2>
            <p>Here you can view your grades.</p>
        </div>
    );
};

export default Grades;